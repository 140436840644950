<template>
  <v-container
      id="projects" fluid
      align="center"
  >
    <base-material-card
        icon="mdi-credit-card"
        inline
        title="Payment Methods"
    >
      <template v-if="loading">
        <v-progress-linear
            indeterminate
            color="primary"
        ></v-progress-linear>
        <v-skeleton-loader loading class="mx-auto" type="card"></v-skeleton-loader>
      </template>
      <v-row v-else>
        <v-col cols="12" v-if="paymentMethods.length > 0" class="text-left mt-7 ml-4">
          <v-btn
              v-if="canAccess({module: 'saas', method: 'get', route: '/paymentMethod'})"
              class="mr-2"
              dark
              small
              color="purple"
              @click="getPaymentMethod"
          >
            <v-icon color="white">mdi-refresh</v-icon>
          </v-btn>
          <v-btn
              v-if="canAccess({module: 'saas', method: 'post', route: '/paymentMethod'})"
              class="mr-2"
              dark
              right
              small
              color="success"
              @click="addNew()"
          >
            <v-icon color="white" class="mr-1">mdi-plus</v-icon>
            Add New Card
          </v-btn>
        </v-col>

        <v-col class="col-12 col-sm-12"
               :class="(oneCard.showProjects) ? 'col-md-12 col-xl-12' : 'col-md-6 col-xl-4'"
               v-for="(oneCard, i) in paymentMethods" :key="i">
          <v-card elevation="5" class="pt-10" :id="oneCard.id">
            <vue-paycard :value-fields="oneCard.display"/>
            <v-divider class="mt-10"></v-divider>
            <v-container class="mt-1">
              <v-row>
                <v-col cols="6"></v-col>

                <v-col cols="6" class="text-right">
                  <v-tooltip top v-if="!oneCard.showProjects">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                          v-bind="attrs"
                          v-on="on"
                          class="mt-2"
                          small
                          @click="viewProjects(oneCard)"
                          color="grey">
                        <v-icon>mdi-chevron-down</v-icon>
                      </v-btn>

                    </template>
                    <span>Show Projects on Card</span>
                  </v-tooltip>

                  <v-btn v-if="oneCard.showProjects"
                         class="mt-2"
                         small
                         @click="hideProjects(oneCard)"
                         color="grey">
                    <v-icon>mdi-chevron-up</v-icon>
                  </v-btn>

                  <v-btn
                      class="mt-2"
                      small
                      @click="editPaymentMethod(oneCard)"
                      color="success">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>

                  <v-btn
                      class="mt-2"
                      small
                      v-if="canAccess({module: 'saas', method: 'delete', route: '/paymentMethod/:id'})"
                      @click="deletePaymentMethod(oneCard)"
                      color="error">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row v-if="oneCard.showProjects && oneCard.showProjects===true">
                <v-col>
                  <v-data-table
                      :loading="oneCard.loading"
                      loading-text="Loading... Please wait"
                      v-model="selected"
                      :headers="headers"
                      :items="oneCard.projects"
                      :single-expand="false"
                      item-key="id"
                      :sort-by="defaultSortKey"
                  >
                    <template v-slot:item.type="{ item }">
                      <v-chip
                          v-if="item.subscription"
                          label
                          :color="item.subscription.color"
                          class="white--text"
                          :class="item.subscription.colorVariation"
                      >
                        {{ item.subscription.package }}
                      </v-chip>
                    </template>

                    <template v-slot:item.name="{ item }">
                      {{ item.name }}
                    </template>

                    <template v-slot:item.code="{ item }">
                      <v-chip
                          class="ml-1"
                          color="success"
                          dark
                          small
                          label
                      >
                        {{ item.code.toUpperCase() }}
                      </v-chip>
                    </template>

                    <template v-slot:item.subscription="{ item }">
                      <v-progress-linear
                          v-if="item.status !=='ready'"
                          :color="item.codeColor"
                          height="25"
                          :striped="item.status==='pending'"
                          :indeterminate="item.status==='pending'"
                          class="text-capitalize"
                      >{{ item.status }}
                      </v-progress-linear>
                      <span v-else>
                <v-chip>
                    {{ new Date(item.subscription.expDate).toDateString() }}
                </v-chip>
                  </span>
                    </template>

                    <template v-slot:item.actions="{ item }">

                      <v-tooltip top v-if="item.status ==='ready' ">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                              v-bind="attrs"
                              v-on="on"
                              v-if="canAccess({module: 'saas', method: 'put', route: '/project/:id/:mode'})"
                              color="success"
                              fab
                              x-small
                              class="ml-2 float-right"
                              @click="editProject(item)"
                          >
                            <v-icon color="white" x-small>mdi-pencil</v-icon>
                          </v-btn>

                        </template>
                        <span>Edit Project</span>
                      </v-tooltip>

                    </template>

                  </v-data-table>

                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6"><h2 class="ma-2">Billing Details</h2>
                </v-col>
              </v-row>
              <v-row>
                <v-col md="6" xs="12">
                  <v-list two-line>
                    <v-list-item>
                      <v-list-item-icon class="mb-3">
                        <v-icon color="blue lighten-1" large>mdi-account</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content class="ml-3">
                        <v-list-item-title class="font-weight-bold">Name</v-list-item-title>
                        <v-list-item-subtitle>
                          {{ oneCard.billing_details.name }}
                          <v-chip label small v-if="!oneCard.billing_details.name" color="error">N/A</v-chip>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                      <v-list-item-icon class="mb-3">
                        <v-icon color="blue lighten-1" large>mdi-phone</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content class="ml-3">
                        <v-list-item-title class="font-weight-bold">Phone</v-list-item-title>
                        <v-list-item-subtitle>
                          {{ oneCard.billing_details.phone }}
                          <v-chip label small v-if="!oneCard.billing_details.phone" color="error">N/A</v-chip>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
                <v-col md="6" xs="12">
                  <v-list two-line>
                    <v-list-item>
                      <v-list-item-icon class="mb-3">
                        <v-icon color="blue lighten-1" large>mdi-mail</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content class="ml-3">
                        <v-list-item-title class="font-weight-bold">Email</v-list-item-title>
                        <v-list-item-subtitle>
                          {{ oneCard.billing_details.email }}
                          <v-chip label small v-if="!oneCard.billing_details.email" color="error">N/A</v-chip>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                      <v-list-item-icon class="mb-3">
                        <v-icon color="blue lighten-1" large>mdi-map-marker</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content class="ml-3">
                        <v-list-item-title class="font-weight-bold">Address</v-list-item-title>
                        <v-list-item-subtitle>
                          {{ oneCard.billing_details.address.line1 }}
                          {{ oneCard.billing_details.address.line2 }} -
                          {{ oneCard.billing_details.address.city }},
                          {{ oneCard.billing_details.address.state }},
                          {{ oneCard.billing_details.address.postal_code }} -
                          {{ oneCard.billing_details.address.country }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
        <v-col cols="12" v-if="paymentMethods.length === 0">
          <br/>
          <v-row no-gutters>
            <v-col cols="12">
              <v-alert type="warning"
                       elevation="2"
                       prominent
                       text class="mt-5 white--text">
                <v-row align="center"
                       no-gutters>
                  <v-col class="grow">
                    <h3>Payment Methods</h3>
                    No Payment Method found.
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col class="shrink">
                    <v-btn
                        v-if="canAccess({module: 'saas', method: 'post', route: '/paymentMethod'})"
                        outlined
                        color="warning"
                        @click="addNew()"
                    >
                      <v-icon class="mr-1">mdi-plus</v-icon>
                      Add
                    </v-btn>
                  </v-col>
                </v-row>
              </v-alert>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-dialog persistent
                v-model="checkDialog"
                width="60%"
      >
        <v-card v-if="oneCard">
          <v-card-title class="text-h2 white--text pb-3" :style="{'background-color': '#2E9EF2'}">
            Changing Payment Method
          </v-card-title>

          <v-card-text class="text-left">
            You are about to remove the payment method ( {{ oneCard.card.brand }} - {{ oneCard.card.last4 }}) from your
            account.<br>
            However, you have projects that use this payment method.
            <br>
            You need to set another payment method for these projects before you can delete this one.

            <h4 class="my-4">Select another payment method:</h4>

            <v-select
                :items="alternateMethods"
                label="Payment Method"
                prepend-icon="mdi-credit-card"
                v-model="pmIdAlt"
                item-text="name"
                item-value="id"
            >
              <template v-slot:selection="{ item }">
                {{ item.card.brand }} - {{ item.card.last4 }} ({{ item.card.exp_month }}/{{ item.card.exp_year }})
              </template>

              <template v-slot:item="{item}">
                {{ item.card.brand }} - {{ item.card.last4 }} ({{ item.card.exp_month }}/{{ item.card.exp_year }})
              </template>

            </v-select>

          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-btn v-if="!replaceLoading" color="error" @click="cancelEdit">
              Cancel
            </v-btn>

            <v-spacer></v-spacer>

            <v-progress-circular
                indeterminate v-if="replaceLoading"
                color="success"
                size="22"
            />
            <v-btn color="success" v-if="!replaceLoading" @click="replace(oneCard)">
              Replace
            </v-btn>

          </v-card-actions>
        </v-card>
      </v-dialog>

    </base-material-card>
  </v-container>
</template>

<script>

import globalMixins from "../../../../mixins/globalMixins";
import {VuePaycard} from 'vue-paycard'

const data = {
  loading: true,
  replaceLoading: false,
  pmIdAlt: '',
  paymentMethods: [],
  alternateMethods: [],
  checkDialog: false,
  oneCard: false,
  search: '',
  headers: [
    {
      'value': 'type', 'text': 'Package', 'sortable': true
    },
    {
      'value': 'name', 'text': 'Name', 'sortable': true,
    },
    {
      'value': 'description', 'text': 'Description', 'sortable': false
    },
    {
      'value': 'code', 'text': 'Code', 'sortable': true, 'width': '12%',
    },
    {
      'value': 'subscription', 'text': 'Expires On',
      'width': '12%',
      'sortable': false,
    },
    {
      text: 'Actions', value: 'actions',
      width: '10%',
      sortable: false, align: 'end'
    },
  ],
  defaultSortKey: ['type'],
  projects: [],
  selected: [],
  expanded: []
};

const methods = {

  cancelEdit() {
    this.checkDialog = false;
  },

  addNew() {
    this.$router.push({name: 'addPayment'});
  },

  async getPaymentMethod() {
    const self = this;
    this.loading = true;
    self.paymentMethods = [];
    this.clearMessages();
    self.getSendData({
      noLoading: true,
      url: '/saas/paymentMethod',
      method: 'get',
      params: {}
    }).then(response => {
      self.loading = false;
      response.rows.forEach((oneCard) => {
        if (!oneCard.billing_details.name || oneCard.billing_details.name.trim() === '') {
          oneCard.billing_details.name = null;
        }

        if (!oneCard.billing_details.email || oneCard.billing_details.email.trim() === '') {
          oneCard.billing_details.email = null;
        }

        if (!oneCard.billing_details.phone || oneCard.billing_details.phone.trim() === '') {
          oneCard.billing_details.phone = null;
        }

        oneCard.display = {
          cardName: oneCard.billing_details.name,
          cardNumber: "xxxx xxxx xxxx " + oneCard.card.last4,
          cardMonth: oneCard.card.exp_month,
          cardYear: oneCard.card.exp_year
        };
      });
      self.paymentMethods = response.rows;
    }).catch(error => {
      self.loading = false;
    });

  },

  async editPaymentMethod(oneCard) {
    this.goToPage({route: 'editPayment', params: {id: oneCard.id}});
  },

  async deletePaymentMethod(oneCard) {
    console.log(oneCard);
    if (confirm(`Are you sure you want to remove credit card ending ${oneCard.card.last4}?`)) {
      const self = this;
      self.getSendData({
        url: `/saas/paymentMethod/${oneCard.id}`,
        method: 'delete',
        params: {}
      }).then(response => {
        self.loading = false;
        setTimeout(() => {
          self.getPaymentMethod();
          self.pushMessage({
            type: 'success',
            title: 'Credit Card Deleted',
            text: 'The selected credit card has been deleted.'
          });
        }, 500);
      }).catch(error => {
        if (error.errors['630']) {
          if (self.paymentMethods.length > 1) {
            self.clearMessages();
            self.openReplace(oneCard);
          }
        }
        self.loading = false;
      });
    }
  },

  replace(oneCard) {
    const self = this;
    self.replaceLoading = true;
    let options = {
      noLoading: true,
      url: `/saas/payment/replace/${oneCard.id}`,
      method: 'patch',
      params: {
        pmIdAlt: self.pmIdAlt
      }
    };

    self.getSendData(options)
        .then(response => {
          self.replaceLoading = false;
          self.checkDialog = false;
          self.alternateMethods = [];
          self.pushMessage({
            type: 'success',
            title: 'Credit Card Replaced',
            text: 'The selected credit card has been replaced.'
          });
          self.getPaymentMethod();
          // call delete
          self.deletePaymentMethod(oneCard);
        })
        .catch(error => {
          self.replaceLoading = false;
          self.checkDialog = false;
          self.alternateMethods = [];
        });
  },

  openReplace(oneCard) {
    const self = this;
    self.oneCard = oneCard;
    // Open modal
    self.checkDialog = true;
    self.alternateMethods = [];
    self.paymentMethods.forEach(function (one) {
      if (oneCard.id !== one.id) {
        self.alternateMethods.push(one);
      }
    });
  },

  viewProjects(oneCard) {
    const self = this;
    oneCard.showProjects = true;
    oneCard.loading = true;
    this.$nextTick(() => {
      this.$forceUpdate();
      setTimeout(function () {
        self.scrollMeTo(oneCard.id);
      }, 100);
    });

    oneCard.projects = [];
    self.getSendData({
      noLoading: true,
      url: '/saas/project',
      method: 'get',
      params: {
        type: 'all',
        pmId: oneCard.id
      }
    }).then(response => {
      oneCard.projects = response;
      oneCard.loading = false;
      self.$nextTick(() => {
        self.$forceUpdate();
      });

    }).catch(error => {
      oneCard.loading = false;
    });
  },

  hideProjects(oneCard) {
    oneCard.showProjects = false;
    this.$nextTick(() => {
      this.$forceUpdate();
    });
  },

  editProject(project) {
    this.$router.push({
      name: 'editProject',
      params: {type: project.type, id: project.id}
    })
  },
};

export default {
  name: 'PaymentMethods',
  methods: methods,
  props: {},
  components: {
    VuePaycard
  },

  computed: {},

  mixins: [globalMixins],

  async created() {
    await this.getPaymentMethod();
  },

  data: () => (data),
}
</script>
